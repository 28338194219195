import { NavLink } from "react-router-dom"

const Nav = () => {
    return <>
        <div className="header-top d-flex flex-column h-100" style={{ background: '#fff', minHeight: 1000, width: 200 }}>
            <NavLink to={'/tai-khoan'} className="px-2 w-100">Tài khoản</NavLink>
            <NavLink to={'/rut-tien'} className="px-2 w-100">Rút tiền</NavLink>
            <NavLink to={'/lich-su-rut-tien'} className="px-2 w-100">Lịch sử rút tiền</NavLink>
            <NavLink to={'/lich-su-nap-tien'} className="px-2 w-100">Lịch sử nạp tiền</NavLink>
            <NavLink to={'/lich-su-dat-cuoc'} className="px-2 w-100">Lịch sử đặt cược</NavLink>
            <NavLink to={'/the-ngan-hang'} className="px-2 w-100">Quản lí tài khoản ngân hàng</NavLink>
            <NavLink to={'/doi-mat-khau'} className="px-2 w-100">Đổi mật khẩu</NavLink>
            <NavLink to={'/mat-khau-rut-tien'} className="px-2 w-100">Đổi mật khẩu rút tiền</NavLink>
        </div>
    </>

}

export default Nav