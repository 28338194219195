import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react-pro';

interface IDeleteModal {
  visible: boolean;
  setVisible: (item: boolean) => void;
  labelText?: string;
  title?: string;
  label?: string;
  labelTextName?: string;
  onDelete: (item: void) => void;
  titleName?: string
}

const DeleteModal = (props: IDeleteModal) => {
  const { visible, setVisible, onDelete, label, title, labelText, labelTextName, titleName } = props;
  const handleVisibleListener = (key: boolean) => () => setVisible(key);

  const handleConfirmDelete = () => {
    onDelete();
    setVisible(false);
  };

  return (
    <>
      <CModal visible={visible} onClose={handleVisibleListener(false)} alignment="center" backdrop="static">
        <CModalHeader className="h5 fw-bold" closeButton={false}>
          {title || 'Xác nhận xoá'}
        </CModalHeader>
        <CModalBody>
          <p className="text-break">
            {labelText ? (
              <>
                {labelText} <b>{labelTextName}</b>
              </>
            ) : (
              <>
                Bạn có chắc muốn xoá {titleName} <b>{label}</b>
              </>
            )}
          </p>
        </CModalBody>
        <CModalFooter className="d-flex justify-content-end">
          <CButton className="mx-2 custom-form-btn white-btn" onClick={handleVisibleListener(false)}>
            Huỷ
          </CButton>
          <CButton className="text-white custom-form-btn ms-2 danger-btn" onClick={handleConfirmDelete}>
            Đồng ý
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default DeleteModal;
