import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CImage,
  CInputGroup,
  CInputGroupText,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ResponseStatus, mapResponseStatus, responseStatusArray } from '../../../shared/enumeration/ResponseStatus';
import { IParams, ISelectValue } from '../../../shared/shared-interfaces';
import SModal from '../../shared/Modal/SModal';
import EyeIcon from '../../shared/icons/EyeIcon';
import EyeOffIcon from '../../shared/icons/EyeOffIcon';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { INewBank, IBank } from '../../../shared/model/bank.model';
import { AppDispatch } from '../../../store';
import { ToastSuccess } from '../../shared/toast/Toast';
import { createEntity, updateEntity } from './banksManagement.api';
import { fetching, resetEntity } from './banksManagement.reducer';
import { permissionSelectors } from '../SystemSetting/PermissionGroup/permission.reducer';
import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer';
import { handleUploadImage } from '@/shared/utils/ultils';
import CIcon from '@coreui/icons-react';
import { cisCameraPlus } from '@coreui/icons-pro';

interface IBankUpdateProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  bankObj?: IBank;
}

const BankUpdate = (props: IBankUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.banksReducer);
  const { updateEntitySuccess } = initialState;
  const { visible, setVisible, bankObj } = props;
  const handleOnClose = () => {
    setVisible(false);
  };

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);
  const permissionItems = useSelector(permissionSelectors.selectAll);

  const [permissionFilter, setPermissionFilter] = useState<IParams>({
    page: 1,
    limit: 20,
  });


  useEffect(() => {
    if (updateEntitySuccess) {
      setVisible(false);
      ToastSuccess(bankObj ? 'Cập nhật ngân hàng thành công' : 'Tạo ngân hàng thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);


  const handleFileUploadAvatar = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileAvatar', inputFiles[0])
        setFieldValue('avatar', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }

  const handleRemappingImageValue = async (value: INewBank) => {
    if (value.fileAvatar) {
      const avatarUrlPromises = await handleUploadImage(value.fileAvatar)
      value.avatar = avatarUrlPromises
    }
    return value
  }


  const initialValues: INewBank = {
    code: '',
    name: '',
    avatar: '',
    fileAvatar: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Không được để trống'),
      // avattar: Yup.string()
      // .trim()
      // .required('Không được để trống')
  });

  const returnOption = (optionValue: number | null | undefined, array: ISelectValue<string>[]) => {
    if (!optionValue) return null;
    if (!array.length) return null;
    return array.find(({ value }) => Number(value) === optionValue);
  };

  return (
    <SModal visible={visible} onClose={handleOnClose} backdrop="static" className="custom-modal">
      <Formik
        initialValues={bankObj || initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={ async (value) => {
          const values = await handleRemappingImageValue(value)
          dispatch(fetching())
          if (bankObj) {
            dispatch(updateEntity(values as IBank));
          } else {
            dispatch(createEntity(values));
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
              <CModalHeader>{bankObj ? 'Chỉnh sửa ngân hàng' : 'Tạo ngân hàng'}</CModalHeader>
              <CModalBody>
                <CRow className="g-4">


                <CCol xs={12}>
                    <CRow>
                      <CCol xs={12}>
                        <CFormLabel className="fw-bold text-sm mb-2 required-field">
                          Chọn ảnh đại diện ngân hàng
                        </CFormLabel>
                        <div className="d-flex justify-content-center" style={{ background: "#f6f6f6"}}>
                          <div className="profileImageContainer">
                            <div
                              className="position-relative"
                              style={{ height: 100 }}
                            >
                              {values.avatar ? (
                                <CImage
                                  color="secondary"
                                  rounded
                                  className="avatar-xxl object-fit-cover"
                                  src={values.avatar}
                                  style={{ width: '100%', height: '100%' }}
                                />
                              ) : (
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center"
                                  style={{ width: '100%', height: '100%' }}
                                >
                                  <CIcon
                                    icon={cisCameraPlus}
                                    className="mb-3"
                                    style={{ width: '33px', height: '33px' }}
                                  />
                                  <p>Chọn ảnh</p>
                                </div>
                              )}
                            </div>
                            <CFormLabel
                              htmlFor="custom-task-file-input"
                              className={`p-image`}
                            >
                              {values.avatar ? (
                                <CIcon
                                  icon={cisCameraPlus}
                                  color="secondary"
                                  className="upload-button"
                                />
                              ) : (
                                <div className="add-image"></div>
                              )}
                              <input
                                id="custom-task-file-input"
                                className="file-upload"
                                type="file"
                                onClick={(e) => (e.currentTarget.value = '')}
                                onChange={(e) =>
                                  handleFileUploadAvatar(e, setFieldValue)
                                }
                                // accept={inputFileTypesAccept}
                                accept="image/*"
                              />
                            </CFormLabel>
                          </div>
                        </div>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.avatar && touched.avatar
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.avatar}
                        </CFormFeedback>
                      </CCol>
                    </CRow>
                  </CCol>

                  {/* <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Tên viết tắt</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="code"
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.code && touched.code}
                    />
                    <CFormFeedback invalid className={!!errors.code && touched.code ? 'd-block' : 'd-none'}>
                      {errors.code}
                    </CFormFeedback>
                  </CCol> */}

                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Tên ngân hàng</CFormLabel>

                    <CFormInput
                      className="form-height-44"
                      name="name"
                      autoComplete="off"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.name && touched.name}
                    />
                    <CFormFeedback invalid className={!!errors.name && touched.name ? 'd-block' : 'd-none'}>
                      {errors.name}
                    </CFormFeedback>
                  </CCol>


                </CRow>
              </CModalBody>
              <CModalFooter className="d-flex justify-content-end">
                <CButton
                  className="btn-custom minw-120 variant-gray-300"
                  type="button"
                  onClick={() => {
                    resetForm();
                    setVisible(false);
                  }}
                >
                  Huỷ
                </CButton>
                <CButton className="btn-custom minw-120 primary-500" type="submit">
                  Lưu
                </CButton>
              </CModalFooter>
            </>
          </CForm>
        )}
      </Formik>
    </SModal>
  );
};

export default BankUpdate;
