import { NavLink } from 'react-router-dom'
import home from '../../../assets/img/bottom/1.png'
import homeActive from '../../../assets/img/bottom/active/1.png'

import uuDai from '../../../assets/img/bottom/2.png'
import uuDaiActive from '../../../assets/img/bottom/active/2.png'

import cskh from '../../../assets/img/bottom/4.png'

import caNhan from '../../../assets/img/bottom/3.png'
import caNhanActive from '../../../assets/img/bottom/active/3.png'

const MenuBottom = () => {
  return (
    <div className="menu-bottom-wap d-flex justify-content-between align-items-center">
      <NavLink
        to={'/'}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <img className='iat' width={21} src={home} />
        <img className='at' width={21} src={homeActive} />
        <span>Sảnh xổ số</span>
      </NavLink>
      <NavLink
        to={'/history-order'}
        className="text-center w-100 d-flex flex-column  align-items-center"
      >
        <img className='iat' width={21} src={uuDai} />
        <img className='at' width={21} src={uuDaiActive} />
        <span>Đặt cược</span>
      </NavLink>
      <NavLink
        to={'/message'}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img width={22} height={22} src={cskh} />
        <span>CSKH</span>
      </NavLink>
      <NavLink
        to={'/account'}
        className="text-center w-100 d-flex flex-column align-items-center"
      >
        <img className='iat' width={22} src={caNhan} />
        <img className='at' width={22} src={caNhanActive} />
        <span>Cá nhân</span>
      </NavLink>
    </div>
  )
}

export default MenuBottom
