import { IGameSidebar } from '@/shared/model/gameSidebar.model'
import { useEffect, useState } from 'react'
import axios from '../../../../shared/config/axios-interceptor'
import { IGameName } from '@/shared/model/gameName.model'
import RenderImageF1 from '../../Wap/RenderImageF1'

interface IProp {
  game: IGameName | null
  datCuocHandle: any
  setChonCuoc: any
  chonCuoc: any
}

const BoxchonCuoc = (props: IProp) => {
  const { game, datCuocHandle, setChonCuoc, chonCuoc } = props
  const [boxCuoc, setBoxCuoc] = useState<IGameSidebar[]>([])

  const getBoxCuoc = async () => {
    try {
      const response = await axios.get('/bidatcuoc/listcuoc/' + game?.id)
      setBoxCuoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getBoxCuoc()
  }, [game])

  const renderIndex = (length: number) => {
    if (length <= 5) return 3
    return 5
  }

  const getSoTien = (object: any) => {
    const check: any = chonCuoc.find(
      (item: any) => item.biDatCuocId === object.biDatCuocId,
    )
    if (check) {
      return check.amount
    }
    return ''
  }


  const onChangeSoTien = (st: number, object: any) => {
    const check: any = chonCuoc.find(
      (item: any) => item.biDatCuocId === object.biDatCuocId,
    )
    if (!check) {
      setChonCuoc([...chonCuoc, { ...object, amount: st }])
    } else {
      setChonCuoc(
        chonCuoc.map((item: any) =>
          item.biDatCuocId === object.biDatCuocId
            ? { ...object, amount: st }
            : item,
        ),
      )
    }
  }

  const renderName = (name: any) => {

    if(game?.group === 1) return name;
    if(game?.group === 2) return name;
    if(game?.group === 3) return <RenderImageF1 ball={Number(name)} width={20} height={20} />
    
    return name;
  }


  return (
    <div
      className="boxchon"
      style={{
        gridTemplateColumns: `repeat(${renderIndex(boxCuoc.length)}, 1fr)`,
      }}
    >
      {boxCuoc.map((item: IGameSidebar, index) => {
        return (
          <div className="item" key={index}>
            <div className="title text-center">{item.name}</div>
            <div className="content">
              {item.biDatCuoc.map((it, id) => {
                return (
                  <div
                    key={id}
                    onClick={() => datCuocHandle(it)}
                    className={
                      chonCuoc.find(
                        (item: any) => item.biDatCuocId === it.biDatCuocId,
                      )
                        ? 'box d-flex justify-content-between w-100 active'
                        : 'box d-flex justify-content-between w-100'
                    }
                  >
                    <div className="name" style={{ width: '40%' }}>
                      { renderName(it.name) }
                    </div>
                    <div className="rate" style={{ width: 50 }}>
                      {it.rate}
                    </div>
                    <div className="amount">
                      <input
                        value={getSoTien(it)}
                        onChange={(e: any) =>
                          onChangeSoTien(e.target.value, it)
                        }
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default BoxchonCuoc
