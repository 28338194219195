import { IGame } from "@/shared/model/game.model"
import { IGameName } from "@/shared/model/gameName.model"
import BoxKetQua from "../BoxKetQua"

interface IProp {
    ketQuaKyTruoc: IGame | null
    game: IGameName | null,
    ky: IGame | null
}


const Pk10KetQua = (props: IProp) => {
    const { ketQuaKyTruoc, game, ky } = props
    return (
      <BoxKetQua ketQuaKyTruoc={ketQuaKyTruoc} game={game} ky={ky} />

    )
}
export default Pk10KetQua;