import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import ArrowRight from '@/components/shared/icons/web/ArrowRight'
import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import share from '../../../assets/img/share.jpg'
import share1 from '../../../assets/img/share1.jpg'

export const MaGioiThieu = () => {
  const { navigate } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication)

  return (
    <div style={{ background: '#fdecda' }} className="box-history-order box-history-order-wap box-account-info-wap d-flex flex-column h-100">
      <div className="box-header d-flex justify-content-center align-items-center">
        <ArrowLeft
          style={{ position: 'absolute', left: 16 }}
          onClick={() => navigate(-1)}
        />

        <h5 className="text-white text-center mb-0 mt-0">Mã giới thiệu</h5>
      </div>

      <div className="content-account-info-wap p-0 d-flex flex-column">
        <img className="w-100" src={share} />

        <div style={{ background: '#fdecda' }} className='h-100 pt-2'>
          <div className="w-75 m-auto position-relative">
             <img className="w-100" src={share1} />
             <span className='text-header'>Mã của bạn là</span>
             <span className='text-username'>{ user?.username }</span>
          </div>
        </div>
      </div>
    </div>
  )
}
