import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import { RootState } from '@/reducers'
import { IGameSidebar, INewGameSidebar } from '@/shared/model/gameSidebar.model'
import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import {
  ErrorMessage,
  mapErrorMessage,
} from '@/shared/enumeration/errorMessage'
import { fetching, resetAll, resetEntity } from './gameSidebarManagement.reducer'
import { createEntity, updateEntity } from './gameSidebarManagement.api'

interface IProcedureStepModal {
  visible: boolean
  onClose: () => void
  gameId: number
  procedureStep?: IGameSidebar
}

const GameSidebarModal = (props: IProcedureStepModal) => {
  const { visible, onClose, procedureStep, gameId } = props
  const dispatch = useDispatch()
  const { initialState } = useSelector(
    (state: RootState) => state.gameSidebarReducer,
  )
  const { updateEntitySuccess, errorMessage } = initialState
  const initialValues: INewGameSidebar = {
    name: '',
    biDatCuoc: [],
    index: 0,
    gameId: gameId,
  }

  console.log(procedureStep)

  useEffect(() => {
    if (updateEntitySuccess) {
      ToastSuccess(
        procedureStep
          ? 'Chỉnh sửa giải thành công'
          : 'Tạo mới giải thành công',
      )
      dispatch(resetEntity())
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess])

  useEffect(() => {
    if (errorMessage) {
      ToastError(
        mapErrorMessage[errorMessage as ErrorMessage] ||
          'Có lỗi xảy ra, xin vui lòng thử lại!',
      )
      dispatch(resetAll())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Vui lòng nhập nhập Tên giải')
      .max(255, 'Số ký tự tối đa là 255 ký tự'),
  })

  return (
    <>
      <CModal visible={visible} onClose={onClose} backdrop="static">
        <CModalHeader className="fw-bold">
          {procedureStep ? 'Chỉnh sửa giải' : 'Thêm mới giải'}
        </CModalHeader>

        <Formik
          enableReinitialize
          initialValues={procedureStep || initialValues}
          validationSchema={validationSchema}
          onSubmit={(value, { resetForm }) => {
            dispatch(fetching())
            if (procedureStep) {
              dispatch(updateEntity(value as IGameSidebar))
            } else {
              dispatch(createEntity(value))
            }
            resetForm()
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleSubmit,
            resetForm,
            handleBlur,
          }) => (
            <CForm onSubmit={handleSubmit}>
              {' '}
              <CModalBody className=" ">
                <CRow className="mb-3">
                  <CCol xs={12} className="mb-3">
                    <CFormLabel
                      className="m-0 fw-bold required-field"
                      htmlFor="name"
                    >
                      Tên giải
                    </CFormLabel>
                    <CFormInput
                      invalid={!!errors.name && touched.name}
                      id="name"
                      name="name"
                      value={values.name}
                      placeholder="Nhập tên giải"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <CFormFeedback
                      invalid
                      className={
                        !!errors.name && touched.name ? 'd-block' : 'd-none'
                      }
                    >
                      {errors.name}
                    </CFormFeedback>
                  </CCol>
                </CRow>
              </CModalBody>
              <CModalFooter className="border-0 d-flex justify-content-end">
                <CRow className="mb-3">
                  <CCol className="border-0 d-flex justify-content-end">
                    <CButton
                      className="mx-2 custom-form-btn white-btn"
                      onClick={onClose}
                    >
                      Đóng
                    </CButton>
                    <CButton
                      className="text-white custom-form-btn ms-2 green-btn"
                      type="submit"
                    >
                      Lưu
                    </CButton>
                  </CCol>
                </CRow>
              </CModalFooter>
            </CForm>
          )}
        </Formik>
      </CModal>
    </>
  )
}
export default GameSidebarModal
