import Layout from './Layout'
import avatar from '../../../assets/img/avatar.gif'
import { NavLink } from 'react-router-dom'
import WithdrawalIcon from '@/components/shared/icons/web/WithdrawalIcon'
import ArrowNarrowLeftIcon from '@/components/shared/icons/ArrowNarrowLeftIcon'
import ArrowRight from '@/components/shared/icons/web/ArrowRight'
import CardIcon from '@/components/shared/icons/web/CardIcon'
import MenuIcon1 from '@/components/shared/icons/web/MenuIcon1'
import MenuIcon2 from '@/components/shared/icons/web/MenuIcon2'
import MenuIcon3 from '@/components/shared/icons/web/MenuIcon3'
import ChangePassWordIcon from '@/components/shared/icons/web/ChangePassWordIcon'
import LogoutIcon from '@/components/shared/icons/header/LogoutIcon'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { logout, resetAll } from '../auth/auth.reducer'
import MenuIcon2_1 from '@/components/shared/icons/web/MenuIcon2_1'
import SModal from '@/components/shared/Modal/SModal'
import { CButton, CModalBody, CModalFooter, CModalHeader } from '@coreui/react-pro'
import { useState } from 'react'
import MenuIcon1_1 from '@/components/shared/icons/web/MenuIcon1_1'
import avatar1 from '../../../assets/img/avatar1.jpg'

const Account = () => {
  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>()

  const [visible, setVisible] = useState(false)

  const onLogout = () => {
    dispatch(logout())
    dispatch(resetAll())
  }

  return (
    <Layout>
      <div className="box-account">
        <div className="box-header py-3">
          <div className='d-flex gap-3 align-items-center'>
            <img src={avatar1} width={50} height={50} style={{borderRadius: 50}}/>
            <div className='fw-bold'>Chào mừng, {user?.username}</div>
          </div>
          {/* <div className="d-flex gap-2 align-items-center my-2 justify-content-between">
            <div className="text-white" style={{ fontSize: 15 }}>
              ID: {user?.id}
            </div>
            <div className="text-white">{user?.username}</div>
          </div> */}

          <div className="pb-4 d-flex justify-content-center text-center flex-column">
            <div style={{ fontSize: 13, color: '#5d636e' }} className="mt-2">
              Số dư
            </div>
            <div className="text-white fs-4 mt-1">
              {new Intl.NumberFormat('vi-VN').format(user?.money || 0)}
            </div>
          </div>
        </div>

        <div className="d-flex px-3 gap-3">

          <div className='nap fw-bold text-white'>Nạp</div>
          <NavLink className='rut fw-bold text-white' to={'/withdraw'}>Rút</NavLink>

        </div>

        {/* <div className="naprut">
          <NavLink
            to="/withdraw"
            className="gap-2 d-flex align-items-center py-2"
          >
            <WithdrawalIcon height={30} width={30} color={'#1989fa'} />
            Rút điểm
          </NavLink>
        </div> */}

        <div className="list-menu mt-4">
          <NavLink to={'/history-order'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#fff', fontSize: 17 }}
            >
              <MenuIcon1 width={24} height={24} />
              Lịch sử tham gia
            </div>
            <ArrowRight color={'#fff'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/history-order'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#fff', fontSize: 17 }}
            >
              <MenuIcon1_1 color="#fff" width={24} height={24} />
              Biến động số dư
            </div>
            <ArrowRight color={'#fff'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/history-deposit'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#fff', fontSize: 17 }}
            >
              <MenuIcon2 color="#fff" width={24} height={24} />
              Lịch sử nạp
            </div>
            <ArrowRight color={'#fff'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/history-withdraw'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#fff', fontSize: 17 }}
            >
              <MenuIcon2_1 color="#fff" width={24} height={24} />
              Lịch sử rút
            </div>
            <ArrowRight color={'#fff'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/add-bank'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#fff', fontSize: 17 }}
            >
              <MenuIcon3 color="#fff" width={24} height={24} />
              Ngân hàng
            </div>
            <ArrowRight color={'#fff'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/change-password'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#fff', fontSize: 17 }}
            >
              <ChangePassWordIcon fill='#0b88a0' color="#fff" width={24} height={24} />
              Đổi mật khẩu
            </div>
            <ArrowRight color={'#fff'} width={12} height={12} />
          </NavLink>

          <NavLink to={'#'} className="mt-4" onClick={onLogout}>
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#fff', fontSize: 17 }}
            >
              <LogoutIcon fill='#0b88a0' color='#0b88a0' width={24} height={24} />
              Đăng xuất
            </div>
            <ArrowRight color={'#fff'} width={12} height={12} />
          </NavLink>
        </div>
      </div>

      <div>
        <SModal
          visible={visible}
          backdrop="static"
          className="custom-modal"
          alignment="center"
        >
          <CModalBody>
            <h2 className="text-center mb-4">NẠP ĐIỂM</h2>
            <p className='m-0'>Vui lòng liên hệ CSKH để được hướng dẫn nạp điểm</p>
          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton style={{ background: "#ddd", color: "#fff", boxShadow: "none"}} onClick={() => setVisible(false)}>Đóng</CButton>
          </CModalFooter>
        </SModal>
      </div>
    </Layout>
  )
}

export default Account
