import { UPLOAD_API_URL } from '@/shared/config/constants'
import { CKEditor } from '@ckeditor/ckeditor5-react'
// import * as CustomBuild from 'ckeditor5-custom-build'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const defaultToolbar = {
  items: [
    'heading',
    '|',
    'fontFamily',
    'fontBackgroundColor',
    'fontColor',
    'fontSize',
    'highlight',
    '|',
    'bold',
    'italic',
    'underline',
    'link',
    'alignment',
    'bulletedList',
    'numberedList',
    '|',
    'outdent',
    'indent',
    '|',
    'imageUpload',
    'blockQuote',
    'insertTable',
    'undo',
    'redo',
    'horizontalLine',
    'pageBreak',
    '|',
    'findAndReplace',
    'selectAll',
  ],
}

const simpleToolbar = {
  items: [
    'bold',
    'italic',
    'underline',
    'link',
    '|',
    'alignment',
    'bulletedList',
    'numberedList',
    '|',
    'imageUpload',
    'blockQuote',
    'insertTable',
    'undo',
    'redo',
  ],
}
interface ICustomEditorProps {
  readOnly?: boolean
  value?: string
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void
  name: string
  simpleMode?: boolean
  size?: 'sm' | 'lg'
  noBorder?: boolean
}

const CustomFormikEditor = (props: ICustomEditorProps) => {
  const {
    readOnly,
    setFieldValue,
    value,
    name,
    simpleMode,
    size,
    noBorder,
  } = props
  const token =
    localStorage.getItem('authentication_token') ||
    sessionStorage.getItem('authentication_token')
  const url = `${UPLOAD_API_URL}file/document`

  return (
    <div
      className={`custom-editor-settings custom-editor ${
        size ? `size-${size}` : ''
      } ${noBorder ? 'no-border' : ''}`}
    >
      <CKEditor
        editor={ClassicEditor}
        data={value || ''}
        onChange={(event, editor: any) => {
          if (editor && editor?.getData() && setFieldValue) {
            setFieldValue(name, editor?.getData())
          }
        }}
        onReady={(editor) => {
          if (editor) {
            const toolbarElement = editor?.ui?.view?.toolbar?.element || null
            if (readOnly) {
              editor.enableReadOnlyMode(editor?.id)
              if (toolbarElement) {
                toolbarElement.style.display = 'none'
              }
            } else {
              editor.disableReadOnlyMode(editor?.id)
              if (toolbarElement) {
                toolbarElement.style.display = 'block'
              }
            }
          }
        }}
        config={{
          toolbar: simpleMode ? simpleToolbar : defaultToolbar,
          removePlugins: ['Title', 'SpecialCharacters'],
          simpleUpload: {
            uploadUrl: url,
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        }}
      />
    </div>
  )
}

export default CustomFormikEditor
