import { IGame } from '@/shared/model/game.model'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

interface IProps {
  ketQuaKyTruoc: any
  ky: IGame | null
  game: any
}

const getRandomNumber = () => Math.floor(Math.random() * 10) + 1

const BoxKetQua = (props: IProps) => {
  const { ketQuaKyTruoc, ky, game } = props

  const [random, setRandom] = useState<any>({
    ball1: getRandomNumber(),
    ball2: getRandomNumber(),
    ball3: getRandomNumber(),
    ball4: getRandomNumber(),
    ball5: getRandomNumber(),
    ball6: getRandomNumber(),
    ball7: getRandomNumber(),
    ball8: getRandomNumber(),
    ball9: getRandomNumber(),
    ball10: getRandomNumber(),
  })

  useEffect(() => {
    const gameEndTime = dayjs(ky?.endTime).add(3, 'seconds')

    const updateRandomValues = () => {
      const now = dayjs()
      const difference = gameEndTime.diff(now)
      const seconds = Math.floor(difference / 1000)
      console.log(seconds)

      if (seconds > 0 && seconds < 250) {
        setRandom({
          ball1: getRandomNumber(),
          ball2: getRandomNumber(),
          ball3: getRandomNumber(),
          ball4: getRandomNumber(),
          ball5: getRandomNumber(),
          ball6: getRandomNumber(),
          ball7: getRandomNumber(),
          ball8: getRandomNumber(),
          ball9: getRandomNumber(),
          ball10: getRandomNumber(),
        })
      } else {
        setRandom(ketQuaKyTruoc)
      }
    }
    const intervalId = setInterval(updateRandomValues, 200)
    return () => clearInterval(intervalId)
  }, [ky?.endTime])

  return (
    <div className="d-flex flex-column">
      <div className="fw-bold" style={{ color: '#000', fontSize: 12 }}>
        kỳ: {ketQuaKyTruoc?.id}
      </div>
      <div className="d-flex item-bi-quay">
        {Array.from({ length: game?.bi ?? 10 }, (_, i) => i + 1).map(
          (_, index) => {
            const propertyName: any = `ball${index + 1}`
            const ball = random?.[propertyName as keyof IGame] ?? 0
            // Kiểm tra và xử lý giá trị ball nếu cần
            let ballValue: React.ReactNode = null
            if (typeof ball === 'number' || typeof ball === 'string') {
              ballValue = ball
            } else {
              ballValue = '' // hoặc giá trị mặc định phù hợp
            }
            return (
              <div key={index} className={'item item-' + ball}>
                {ballValue}
              </div>
            )
          },
        )}
      </div>
    </div>
  )
}

export default BoxKetQua
