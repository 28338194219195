import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import Edit02Icon from '@/components/shared/icons/Edit02Icon';
import { ToastSuccess } from '@/components/shared/toast/Toast';
import { CButton, CFormInput, CFormSwitch, CInputGroup, CInputGroupText, CRow, CSmartTable } from '@coreui/react-pro';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { ResponseStatus, mapResponseStatus } from '../../../../shared/enumeration/ResponseStatus';
import { IPermission } from '../../../../shared/model/permission.model';
import { createIndexes } from '../../../../shared/utils/ultils';
import { AppDispatch } from '../../../../store';
import CustomTableFooter from '../../../shared/CustomTableFooter/CustomTableFooter';
import FilterIcon from '../../../shared/icons/FilterIcon';
import PlusIcon from '../../../shared/icons/PlusIcon';
import SearchIcon from '../../../shared/icons/SearchIcon';
import TrashIcon from '../../../shared/icons/TrashIcon';
import PermissionUpdate from './ConfigSystemUpdate';
import { getEntities, removeEntity, updateEntity } from './config-system.api';
import { fetching, permissionSelectors, resetEntity, setFilterState } from './config-system.reducer';

const columns = [
  {
    key: 'index',
    label: '#',
    sorter: false,
  },
  {
    key: 'name',
    label: 'Tên',
    sorter: false,
  },
  {
    key: 'status',
    label: 'Trạng thái',
    sorter: false,
    _props: { width: '30%' },
  },
  {
    key: 'actions',
    label: 'Thao tác',
    sorter: false,
    _props: { className: 'text-center' },
  },
];

interface IIndexPermission extends IPermission {
  index: number;
}
const ConfigSystemManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.permissionReducer);
  const { filterState, totalItems, totalPages, updateEntitySuccess, loading, deleteEntitySuccess } = initialState;
  const [createPermission, setCreatePermission] = useState<boolean>(false);
  const [updatePermission, setUpdatePermission] = useState<IPermission | null>(null);

  const [chosenDelete, setChosenDelete] = useState<IPermission | null>(null);

  const handleCreatePermission = () => {
    setCreatePermission(true);
  };

  const handleUpdatePermission = (permission: IPermission) => () => {
    setUpdatePermission(permission);
  };

  const handleCancelUpdatePermission = (update?: boolean) => {
    setUpdatePermission(null);
  };

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0);
      dispatch(setFilterState({ ...filterState, page: page }));
    }
  };

  const permissions = useSelector(permissionSelectors.selectAll);
  const indexedPermission = createIndexes(permissions, filterState);

  useEffect(() => {
    if (updateEntitySuccess && !createPermission && !updatePermission) {
      ToastSuccess('Cập nhật trạng thái thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching());
      dispatch(getEntities(filterState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), deleteEntitySuccess]);

  useEffect(() => {
    if (deleteEntitySuccess) {
      ToastSuccess('Xoá quyền thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntitySuccess]);

  const handleDelete = () => {
    if (chosenDelete) {
      dispatch(removeEntity(chosenDelete.permissionGroupId));
      setChosenDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setChosenDelete(null);
  };

  return (
    <>
      <div className="tab-outlet-content ">
        <div className="table-context">
          <CButton className="btn-custom primary-500 btn-sm" onClick={handleCreatePermission}>
            <PlusIcon /> Tạo quyền mới
          </CButton>

          <div className="d-none">
            <CInputGroup className="search-group">
              <CInputGroupText id="search-addon">
                <SearchIcon />
              </CInputGroupText>
              <CFormInput placeholder="Tìm kiếm theo tên" aria-label="Username" aria-describedby="basic-addon1" />
            </CInputGroup>
            <CButton className="btn-custom gray-700 btn-sm">
              <FilterIcon /> Lọc
            </CButton>
          </div>
        </div>

{/* 
        {createPermission ? <PermissionUpdate visible={createPermission} setVisible={setCreatePermission} /> : ''}
        {updatePermission ? (
          <PermissionUpdate
            visible={Boolean(updatePermission)}
            permissionObj={updatePermission}
            setVisible={handleCancelUpdatePermission}
          />
        ) : (
          ''
        )} */}
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
      {chosenDelete ? (
        <ConfirmDeleteModal
          visible={Boolean(chosenDelete)}
          handleOnClose={handleCancelDelete}
          handleDelete={handleDelete}
          title="Xoá quyền khỏi hệ thống"
          content={
            <>
              Xác nhận xoá quyền <span className="high-light">{chosenDelete.name}</span> khỏi hệ thống
            </>
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default ConfigSystemManagement;
