import TimeIcon from "@/components/shared/icons/web/TimeIcon";
import { IGame } from "@/shared/model/game.model";
import dayjs from "dayjs"
import { useEffect, useState } from "react"


interface IData {
    setDisableDatCuoc: any;
    setLoad: any;
    load: boolean;
    ky: IGame | null
}
const KyQuay = (props: IData) => {

    const { setDisableDatCuoc, setLoad, load, ky } = props

    const [timeInterval, setTimeInterval] = useState<any>({ m: '00', s: '00' })

    useEffect(() => {
        const gameEndTime = dayjs(ky?.endTime).add(2, 'seconds')

        const interval = setInterval(() => {
            const now = dayjs()
            const difference = gameEndTime.diff(now)

            if (difference > 0) {
                const hours = Math.floor(difference / (1000 * 60 * 60))
                const minutes = Math.floor(
                    (difference % (1000 * 60 * 60)) / (1000 * 60),
                )
                const seconds = Math.floor((difference % (1000 * 60)) / 1000)

                if (minutes === 0 && seconds < 15) {
                    setDisableDatCuoc(true)
                } else {
                    setDisableDatCuoc(false)
                }

                let m = minutes < 10 ? '0' + minutes : minutes
                let s = seconds < 10 ? '0' + seconds : seconds
                const formattedCountdown = `${m}:${s}`
                setTimeInterval({ m: m, s: s })
            } else {
                setLoad(!load)
                setTimeInterval({ m: '00', s: '00' })
                clearInterval(interval)
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [ky?.endTime, load])

    return <div className="d-flex gap-2" style={{ width: 320 }}>
        <div className="d-flex flex-column justify-content-between">
            <div className="fw-bold" style={{ fontSize: 12, color: '#000' }}>kỳ: {ky?.id}</div>
            <div className="fw-bold" style={{ fontSize: 12, color: '#000' }}>Thời hạn đặt cược</div>
        </div>

        <div className="d-flex time-quay-so">
            <div className="item">00</div>
            <div className="item">{timeInterval.m}</div>
            <div className="item">{timeInterval.s}</div>
        </div>
    </div>

}

export default KyQuay