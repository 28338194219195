import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { IParams } from '../../../shared/shared-interfaces';
import { IGameName, INewGameName } from '@/shared/model/gameName.model';

const prefix = 'game';
// const history = 'revision';

export interface IGameNameParams extends IParams {
  status?: ResponseStatus;
  gameNameId?: number;
  gameTypeId?: number;
  // projectId?: number; // Lấy tất cả user không phải là nhân viên của dự án được truyền vào
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IGameNameParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IGameName[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity: any = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IGameName>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity: any = createAsyncThunk(`create-one-${prefix}`, async (body: INewGameName, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity: any = createAsyncThunk(`update-one-${prefix}`, async (body: IGameName, thunkAPI) => {
  try {
    const { id } = body;
    const { data } = await axios.put<IGameName>(`${prefix}/${id}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (id: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${id}`);
    return id;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IUserDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IUserDel, thunkAPI) => {
    try {
      await axios.post<IGameName>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const getCurrentDraw = createAsyncThunk(`get-current-${prefix}`, async (id: number, thunkAPI) => {
  try {
    return await axios.get<IGameName[]>(`${prefix}/get-current/${id}`);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});