import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardText,
  CCardTitle,
  CCol,
  CFormLabel,
  CRow,
} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BiEdit, BiTrashAlt } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { fetching as fetchingGameSidebar, gameSidebarSelectors, initialUsersFilter } from './GameSidebar/gameSidebarManagement.reducer'
import { fetching, selectEntityById } from './gameNameManagement.reducer'
import { biDatCuocSelectors, fetching as fetchingBiDatCuoc } from '../BiDatCuoc/biDatCuocManagement.reducer'
import { mapResponseStatus, mapResponseStatusToColor, ResponseStatus } from '@/shared/enumeration/ResponseStatus'
import { IGameSidebar } from '@/shared/model/gameSidebar.model'
import { IBiDatCuoc } from '@/shared/model/biDatCuoc.model'
import CustomDetailBackward from '@/components/shared/CustomDetailBack/CustomDetailBackward'
import { gameSelectors } from '../GameManagement/gameManagement.reducer'
import { getEntities as geBiDatCuocEntities } from '../BiDatCuoc/biDatCuocManagement.api'
import { getEntities as getGameSidebarEntities } from './GameSidebar/gameSidebarManagement.api'
import { getEntities, getEntity } from './gameNameManagement.api'




interface IGameSidebarBox {
  stageItem: IGameSidebar
  active: boolean
}

interface IBiDatCuocBox {
  taskItem: IBiDatCuoc
}

const GameNameDetal = () => {
  const { params, redirectView, navigate } = useRouter()
  const { id } = params
  const dispatch = useDispatch()

  const { initialState } = useSelector(
    (state: RootState) => state.gameNameReducer,
  )
  const { errorMessage, deleteEntitySuccess } = initialState
  const game = useSelector(selectEntityById(id || ''))
  console.log(game);
  
  const gameSidebar = useSelector(gameSidebarSelectors.selectAll)
  const biDatCuoc = useSelector(biDatCuocSelectors.selectAll)
  const [chosenStep, setChosenStep] = useState<any | null>(null)

  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [isTaskDetail, setIsTaskDetail] = useState<boolean>(false)
  const [chosenTask, setChosenTask] = useState<any | null>(null)

  const handleRemoveEntity = () => {
    if (id) {
      const body = {
        id: [Number(game?.id)],
        // status: ResponseStatus.DELETED,
      }
      // dispatch(fetching())
      // dispatch(updateDeleteEntity(body))
    }
  }

  useEffect(() => {
    if (id) {
      dispatch(fetching())
      dispatch(getEntity(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (id) {
      dispatch(fetchingGameSidebar())
      dispatch(
        getGameSidebarEntities({ page: 1, limit: 100, sort: 'createdDate,desc' }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (chosenStep) {
      dispatch(fetchingBiDatCuoc())
      dispatch(
        geBiDatCuocEntities({
          page: 1,
          limit: 100,
          sort: 'createdDate,desc',
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenStep])

  useEffect(() => {
    if (errorMessage) {
      // ToastError(
      //   mapResponseMessageEnum[errorMessage as ResponseMessageEnum] ||
      //     'Có lỗi xảy ra, xin vui lòng thử lại!',
      // )
      // dispatch(resetAll())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage])

  useEffect(() => {
    if (deleteEntitySuccess) {
      // ToastSuccess('Xoá bản ghi Loại tin tức thành công')
      // dispatch(resetEntity())
      // navigate('/procedure')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntitySuccess])

  const ProcedureStepBox = (props: IGameSidebarBox) => {
    const { stageItem, active } = props
    const handleChosenBox = () => {
      setChosenStep(chosenStep?.id === stageItem.id ? null : stageItem)
    }

    return (
      <div
        onClick={handleChosenBox}
        className={`${
          active ? 'green-fade-btn' : 'enterprise-table-box'
        }  d-flex flex-wrap justify-content-between mb-3 rounded cursor-pointer`}
      >
        <div className="p-3 d-flex flex-wrap justify-content-start">
          <div>
            <p className="box-title fw-bold m-0 text-md">{stageItem.name}</p>
            {/* <p className="box-title m-0 text-sm">{stageItem.description}</p>
            <span className="text-xs me-2">
              <AiOutlineClockCircle color="#697586" size={12} />{' '}
              {stageItem.duration} {mapStepUnitEnum[stageItem.unit]}
            </span> */}
          </div>
        </div>
      </div>
    )
  }

  const ProcedureTaskBox = (props: IBiDatCuocBox) => {
    const { taskItem } = props
    const handleChosenBox = () => {
      setIsTaskDetail(true)
      setChosenTask(chosenTask?.id === taskItem.biDatCuocId ? null : taskItem)
    }
    return (
      <div
        className={`enterprise-table-box d-flex flex-wrap justify-content-between mb-3 rounded cursor-pointer`}
        onClick={handleChosenBox}
      >
        <div className="p-3 d-flex flex-wrap justify-content-start">
          <div>
            <p className="box-title m-0 fw-bold text-md">{taskItem.name}</p>
            {/* <p className="box-title m-0 text-sm">{taskItem.description}</p> */}
          </div>
        </div>
      </div>
    )
  }

  const handleCloseTaskDetailModal = () => {
    setIsTaskDetail(false)
    setChosenTask(null)
  }

  return (
    <>
      <div className="tab-outlet-content">
        <CCard className="bg-cms border-0 shadow-none">
          <CustomDetailBackward
            redirectText="Quay lại"
            title={'Thông tin trò chơi'}
            redirect="/admin/game"
          />
          <div className="d-flex justify-content-start flex-wrap">
            <CButton
              className="custom-goback-btn mb-3 me-2"
              onClick={redirectView(`/procedure/${game?.id}/update`)}
            >
              <BiEdit /> Chỉnh sửa Quy trình
            </CButton>
            <CButton
              className={`custom-goback-btn mb-3 me-2 ${
                game?.status === ResponseStatus.INACTIVE
                  ? ''
                  : 'd-none'
              }`}
              onClick={() => setIsDelete(true)}
            >
              <BiTrashAlt /> Xóa Quy trình
            </CButton>
          </div>
          <CCardBody className="border bg-white rounded">
            <CRow>
              <CCol sm={12} md={6} className="mb-3">
                <CRow>
                  <CCol xs={6}>
                    <CFormLabel className="text-sm fw-bold mb-0">
                      Tên trò chơi:
                    </CFormLabel>
                  </CCol>
                  <CCol xs={6}>
                    <CCardText className="text-sm">
                      {game?.name}
                    </CCardText>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm={12} md={6} className="mb-3">
                <CRow>
                  <CCol xs={6}>
                    <CFormLabel className="text-sm fw-bold mb-0">
                      Số phút quay mỗi kỳ:
                    </CFormLabel>
                  </CCol>
                  <CCol xs={6}>
                    <CCardText className="text-sm">
                      {game?.minutes}
                    </CCardText>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm={12} md={6} className="mb-3">
                <CRow>
                  <CCol xs={6}>
                    <CFormLabel className="text-sm fw-bold mb-0">
                      Trạng thái:
                    </CFormLabel>
                  </CCol>
                  <CCol xs={6}>
                    {game?.status && (
                      <CBadge
                      className={`custom-badge badge-${
                        mapResponseStatusToColor[game?.status]
                      }`}
                      >
                        {mapResponseStatus[game?.status]}
                      </CBadge>
                    )}
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm={12} md={6} className="mb-3">
                <CRow>
                  <CCol xs={6}>
                    <CFormLabel className="text-sm fw-bold mb-0">
                      Số kết quả:
                    </CFormLabel>
                  </CCol>
                  <CCol xs={6}>
                    {game?.bi} bi
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
          <CRow className={` mb-5`}>
            <CCol md={12} lg={6} className="mt-3">
              <CCardHeader className="border  bg-white border-bottom-0 d-flex flex-wrap justify-content-between bg-transparent">
                <CCardTitle className="my-1">Giai đoạn</CCardTitle>
              </CCardHeader>
              <CCardBody
                className="border  bg-white rounded-bottom"
                style={{ maxHeight: '409px', overflowY: 'scroll' }}
              >
                <div className="mb-2 fw-semibold">Danh sách giai đoạn</div>
                {gameSidebar && gameSidebar.length > 0 ? (
                  <>
                    {' '}
                    {gameSidebar.map((item, index) => (
                      <ProcedureStepBox
                        key={index}
                        active={chosenStep?.id === item.id}
                        stageItem={item}
                      />
                    ))}
                  </>
                ) : (
                  <div className="text-center">
                    Quy trình này chưa có giai đoạn nào !
                  </div>
                )}
              </CCardBody>
            </CCol>
            <CCol
              md={12}
              lg={6}
              className={`${chosenStep ? '' : 'd-none'} mt-3 `}
            >
              <CCardHeader className="border bg-white border-bottom-0 d-flex flex-wrap justify-content-between bg-transparent">
                <CCardTitle className="my-1">
                  <b>{chosenStep?.name}</b>
                </CCardTitle>
              </CCardHeader>
              <CCardBody
                className="border bg-white rounded-bottom"
                style={{ maxHeight: '409px', overflowY: 'scroll' }}
              >
                <div className="mb-2 fw-semibold">Danh sách nhiệm vụ</div>
                {biDatCuoc && biDatCuoc.length > 0 ? (
                  <>
                    {biDatCuoc.map((item, index) => (
                      <ProcedureTaskBox key={index} taskItem={item} />
                    ))}
                  </>
                ) : (
                  <div className="text-center">
                    Giai đoạn này chưa có nhiệm vụ nào !
                  </div>
                )}
              </CCardBody>
            </CCol>
          </CRow>
        </CCard>
        {/* {procedureItem ? (
        <DeleteModal
          visible={isDelete}
          setVisible={setIsDelete}
          onDelete={handleRemoveEntity}
          label={procedureItem.name}
          titleName={'quy trình'}
        />
      ) : (
        ''
      )}
      {isTaskDetail && chosenTask ? (
        <ProcedureTaskDetailModal
          onClose={handleCloseTaskDetailModal}
          visible={isTaskDetail}
          procedureTask={chosenTask}
        />
      ) : (
        ''
      )} */}
      </div>
    </>
  )
}

export default GameNameDetal
