import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import AddBank from './AddBank';
import Withdraw from './Withdraw';
import HistoryOrder from './HistoryOrder';
import HistoryWithdraw from './HistoryWithdraw';
import HistoryDeposit from './HistoryDeposit';
import ChangePassword from './ChangePassword';
import Message from './Message';
import Home from './Home';
import Account from './Account';
import History from './History';
import Game from './Game';
import Info from './Info';
import TaiKhoan from './TaiKhoan';
import RutTien from './RutTien';
import TheNganHang from './TheNganHang';
import LichSuRuTien from './LichSuRutTien';
import LichSuNapTien from './LichSuNapTien';
import LichSuDatCuoc from './LichSuDatCuoc';
import ThayDoiMatKhau from './ThayDoiMatKhau';
import MatKhauRutTien from './MatKhauRutTien';

// const Home = React.lazy(() => import('./Home'));
// const Account = React.lazy(() => import('./Account'));
// const History = React.lazy(() => import('./History'));

const WebManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/account',
    element: <Account />,
  },

  {
    path: '/history',
    element: <History />,
  },
  {
    path: '/add-bank',
    element: <AddBank />,
  },
  {
    path: '/withdraw',
    element: <Withdraw />,
  },
  {
    path: '/cskh',
    element: <Withdraw />,
  },
  {
    path: '/history-order',
    element: <HistoryOrder />,
  },
  {
    path: '/history-withdraw',
    element: <HistoryWithdraw />,
  },
  {
    path: '/history-deposit',
    element: <HistoryDeposit />,
  },

  {
    path: '/change-password',
    element: <ChangePassword />,
  },

  {
    path: '/message',
    element: <Message />,
  },

  {
    path: '/game/:id',
    element: <Game />,
  },
  {
    path: '/info',
    element: <Info />,
  },
  {
    path: '/tai-khoan',
    element: <TaiKhoan />,
  },
  {
    path: '/rut-tien',
    element: <RutTien />,
  },
  {
    path: '/the-ngan-hang',
    element: <TheNganHang />,
  },
  {
    path: '/lich-su-rut-tien',
    element: <LichSuRuTien />,
  },
  {
    path: '/lich-su-nap-tien',
    element: <LichSuNapTien />,
  },
  {
    path: '/lich-su-dat-cuoc',
    element: <LichSuDatCuoc />,
  },

  {
    path: '/doi-mat-khau',
    element: <ThayDoiMatKhau />,
  },

  {
    path: '/mat-khau-rut-tien',
    element: <MatKhauRutTien />,
  },

  { path: '*', element: <Navigate to="/404" /> },
];

const WebsManagementRoutes = () => useRoutes(WebManagementLayout);
export default WebsManagementRoutes;
