import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IInitialState, IResponse } from '../../../../shared/shared-interfaces';

import {
  createEntity,
  getEntities,
  getCurrentDraw,
  getEntity,
  IGameSidebarParams,
  removeEntity,
  updateDeleteEntity,
  updateEntity,
  updateGameSidebarEntities,
} from './gameSidebarManagement.api';
import { IGameSidebar } from '@/shared/model/gameSidebar.model';
import { RootState } from '@/reducers';

interface IGameSidebarInitialState extends IInitialState {
  updateEntityStatusSuccess: boolean;
  filterState: IGameSidebarParams;
  currentDraw: IGameSidebar | null;
  newEntity: IGameSidebar | null;
  updateStepIndexSuccess: boolean
}

export const initialUsersFilter: IGameSidebarParams = {
  page: 1,
  limit: 15,
  sortBy: 'id',
  sortOrder: 'DESC',
  gameSidebarId: 1
};

const initialState: IGameSidebarInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  updateEntityStatusSuccess: false,
  filterState: initialUsersFilter,
  currentDraw: null,
  newEntity: null,
  updateStepIndexSuccess: false
};

export const gameSidebarAdapter = createEntityAdapter<IGameSidebar>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'gameSidebarSlice',
  initialState: gameSidebarAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<IGameSidebarParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.newEntity = null;
      state.initialState.updateStepIndexSuccess = false;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.newEntity = null;
      state.initialState.updateStepIndexSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCurrentDraw.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IGameSidebar>>) => {
        state.initialState.currentDraw = payload.data;
      }
    );
    builder.addCase(
      getEntities.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<IGameSidebar[]>>>) => {
        gameSidebarAdapter.setAll(state, payload.data.data);

        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(getEntities.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    });

    builder.addCase(getEntity.fulfilled.type, (state, { payload }: PayloadAction<IGameSidebar>) => {
      gameSidebarAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(getEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    });
    builder.addCase(updateEntity.fulfilled.type, (state, { payload }: PayloadAction<IGameSidebar>) => {
      gameSidebarAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(updateEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    });
    builder.addCase(createEntity.fulfilled.type, (state, { payload }: PayloadAction<IGameSidebar>) => {
      gameSidebarAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
      state.initialState.newEntity = payload;
    });
    builder.addCase(createEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    });
    builder.addCase(removeEntity.fulfilled.type, (state, { payload }: PayloadAction<string>) => {
      gameSidebarAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(removeEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    });
    builder.addCase(updateDeleteEntity.fulfilled.type, (state, { payload }: PayloadAction<string[]>) => {
      gameSidebarAdapter.removeMany(state, payload);
      state.initialState.totalItems -= payload.length;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(updateDeleteEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    });

    builder.addCase(updateGameSidebarEntities.fulfilled.type, (state, { payload }: PayloadAction<IGameSidebar[]>) => {
      gameSidebarAdapter.setAll(state, payload);
      state.initialState.totalItems -= payload.length;
      state.initialState.updateStepIndexSuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(updateGameSidebarEntities.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateStepIndexSuccess = false;
    });

  },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const gameSidebarSelectors = gameSidebarAdapter.getSelectors<RootState>((state) => state.gameSidebarReducer);

const { selectById } = gameSidebarAdapter.getSelectors();
const getUserState = (rootState: RootState) => rootState.gameSidebarReducer;

export const selectEntityById = (id: string) => {
  return createSelector(getUserState, (state) => selectById(state, id));
};
