import { useEffect, useRef, useState } from 'react'
import Layout from './Layout'
import ArrowRight from '@/components/shared/icons/web/ArrowRight'
import { NavLink } from 'react-router-dom'
import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import uudai1 from '../../../assets/img/uudai-1.png'
import uudai3 from '../../../assets/img/uudai-3.png'
import uudai4 from '../../../assets/img/uudai-4.png'

const sections = [
  { id: 'section1', title: 'Section 1' },
  { id: 'section2', title: 'Section 2' },
  { id: 'section3', title: 'Section 3' },
]

export const UuDaiWap = () => {
  const { navigate } = useRouter()

  return (
    <div className="box-uu-dai-wap">
      <Layout>
        <div className="box-header d-flex justify-content-center align-items-center">
          <ArrowLeft
            style={{ position: 'absolute', left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="text-white text-center mb-0 mt-0">Ưu Đãi</h5>
        </div>

        <div className="content-uu-dai-wap">
          <div className='item'>
            <img className='w-100' src={uudai1} alt="uudai1" />
            <p>HỢP TÁC ĐẠI LÝ HOA HỒNG LÊN TỚI 55%</p>
          </div>  

          <div className='item'>
            <img className='w-100' src={uudai3} alt="uudai1" />
            <p>GIỚI THIỆU NGAY THƯỞNG LIỀN TAY</p>
          </div>  

          <div className='item'>
            <img className='w-100' src={uudai4} alt="uudai1" />
            <p>XỔ SỐ NHANH HOÀN CƯỢC CỰC CAO</p>
          </div>  
        </div>
      </Layout>
    </div>
  )
}
